import Swup from 'swup';
import imagesLoaded from 'imagesloaded';
import Marquee3k, { init } from 'marquee3000';
// import getWeather from './weather';
// import Flickity from 'flickity';
import Splitting from "splitting";

const swup = new Swup({
  containers: ['#swup', '.header']
});

function runitback() {
  // JavaScript to be fired on all pages

  // Marquee3k
  if (document.querySelector('.marquee3k')) {
    const marqueeImages = document.querySelectorAll('.marquee3k img');
    imagesLoaded( marqueeImages, function() { 
        document.fonts.ready.then(function () {
            Marquee3k.init();
        });
    });
  }




  // WEATHER

  // fetch('https://api.open-meteo.com/v1/forecast?latitude=59.2936634&longitude=18.069969117&daily=precipitation_probability_max&current_weather=true&timeformat=unixtime&timezone=Europe%2FBerlin')
  // .then(function(response) {
  //   return response.json(); // Parse the response as JSON
  // })
  // .then(function(data) {

  //   const weather = {
  //     temperature: data.current_weather.temperature,
  //     weathercode: data.current_weather.weathercode,
  //     windspeed: data.current_weather.windspeed,
  //     chance_of_rain: data.daily.precipitation_probability_max[1]
  //   };

  //   console.log(weather);
    
  //   // set variables for weather data in css variables
  //   document.documentElement.style.setProperty('--temp', weather.temperature);
  //   document.documentElement.style.setProperty('--weathercode', weather.weathercode);
  //   document.documentElement.style.setProperty('--windspeed', weather.windspeed);
  //   document.documentElement.style.setProperty('--precipitation', weather.chance_of_rain);

  // })
  // .catch(function(error) {
  //   console.log('Error:', error);
  // });







  // let rotatedtext = '.wobble, .happenings p, .marquee3k p, .wobble p';
  // let rotatedblocks = 'figure, .blocks p';

  Splitting({
    target: ".marquee3k p, .wobble p, .wobble time",
    by: "chars",
  });

  
  function dance(selector) {
    const chars = document.querySelectorAll(selector);
    for (let i = 0; i < chars.length; i++) {
      chars[i].style.rotate = Math.random() * -5 + 'deg';
      chars[i].style.transform = "translateY(" + Math.random() * 0.1 + "em)";
      chars[i].style.padding = "0 " + Math.random() * 0.08 + "em 0 0";
    }
  }
  dance(".wobble p .char, .wobble time .char, .marquee3k p .char");
  // console.log('dancing');
  // // and dance every nth seconds
  // setInterval(function() {
  //   dance(".wobble p .char");
  // }, 2000);
  
  // // ROTATE IMAGES SLIGHTLY
  // const blocks = document.querySelectorAll(rotatedblocks);
  // for (let i = 0; i < blocks.length; i++) {
  //   // rotate blocks randomly bewteen -1deg and 1ded
  //   blocks[i].style.rotate = Math.random() * 1 - 0.5 + 'deg';
  // }















  //     // get mouse x and y position on mousemove
  //     let mouseX = 0;
  //     let mouseY = 0;    
  //     const chars = document.querySelectorAll('.char');
  //     // update css variable values on mousemove
  //     document.addEventListener('mousemove', function(e) {
  //       mouseX = e.pageX;
  //       mouseY = e.pageY;
  //       // normalize pageX between 1 and -1
  //       mouseX = (mouseX / window.innerWidth) * 2 - 1;
  //       mouseY = (mouseY / window.innerHeight) * 2 - 1;
  //       // rotate each character by normalized mouseX value
  //       for (let i = 0; i < chars.length; i++) {
  //         chars[i].style.rotate = mouseX * -40 + 'deg';
  //         // chars[i].style.rotate = mouseX * -40 * Math.random() + 'deg';
  //       }
  // });
 

  // // GALLERY BLOCK
  // var flky = new Flickity( '.gallery', {
  //   // options, defaults listed
  
  //   accessibility: true,
  //   // enable keyboard navigation, pressing left & right keys
  
  //   adaptiveHeight: false,
  //   // set carousel height to the selected slide
  
  //   autoPlay: false,
  //   // advances to the next cell
  //   // if true, default is 3 seconds
  //   // or set time between advances in milliseconds
  //   // i.e. `autoPlay: 1000` will advance every 1 second
  
  //   cellAlign: 'center',
  //   // alignment of cells, 'center', 'left', or 'right'
  //   // or a decimal 0-1, 0 is beginning (left) of container, 1 is end (right)
  
  //   cellSelector: undefined,
  //   // specify selector for cell elements
  
  //   contain: false,
  //   // will contain cells to container
  //   // so no excess scroll at beginning or end
  //   // has no effect if wrapAround is enabled
  
  //   draggable: '>1',
  //   // enables dragging & flicking
  //   // if at least 2 cells
  
  //   dragThreshold: 3,
  //   // number of pixels a user must scroll horizontally to start dragging
  //   // increase to allow more room for vertical scroll for touch devices
  
  //   freeScroll: false,
  //   // enables content to be freely scrolled and flicked
  //   // without aligning cells
  
  //   friction: 0.2,
  //   // smaller number = easier to flick farther
  
  //   groupCells: false,
  //   // group cells together in slides
  
  //   initialIndex: 0,
  //   // zero-based index of the initial selected cell
  
  //   lazyLoad: true,
  //   // enable lazy-loading images
  //   // set img data-flickity-lazyload="src.jpg"
  //   // set to number to load images adjacent cells
  
  //   percentPosition: true,
  //   // sets positioning in percent values, rather than pixels
  //   // Enable if items have percent widths
  //   // Disable if items have pixel widths, like images
  
  //   prevNextButtons: true,
  //   // creates and enables buttons to click to previous & next cells
  
  //   pageDots: true,
  //   // create and enable page dots
  
  //   resize: true,
  //   // listens to window resize events to adjust size & positions
  
  //   rightToLeft: false,
  //   // enables right-to-left layout
  
  //   setGallerySize: true,
  //   // sets the height of gallery
  //   // disable if gallery already has height set with CSS
  
  //   watchCSS: false,
  //   // watches the content of :after of the element
  //   // activates if #element:after { content: 'flickity' }
  
  //   wrapAround: false
  //   // at end of cells, wraps-around to first for infinite scrolling
  
  // });

};

runitback();

swup.on('pageView', () => {
  // console.log('New page loaded');
  runitback();
});


